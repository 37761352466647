.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(16, 17, 20, 0.5);
    z-index: 10;
}

.modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
}
