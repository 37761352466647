@import "~bootstrap/dist/css/bootstrap.min.css";

* {
  font-family: "Noto Serif", serif;
}

:root {
  --green: #0f4e24;
  --white: #fff;
}

.app-root {
  gap: 50px;
  user-select: none;

  min-height: 100vh;

  background: var(--green);
  color: var(--white);
  box-shadow: inset 0 0 50vh rgba(0, 0, 0, 0.5);
}
input {
  text-align: end;
  border: none;
  background: transparent;
  width: 100%;
}

.inset-0 {
  inset: 0;
}
