////////////////////////////////////////////////////////////////////////////////
// IF YOU WANT TO USE THIS STYLE, PLEASE READ THIS                            //
////////////////////////////////////////////////////////////////////////////////
// This is written in SCSS. If you would like to use the CSS, you may compile //
// the code, then use the CSS style on your own project.                      //
// Please take note that the code above the block of comment:                 //
// STYLES RELATED TO EXPERIMENT BUTTONS                                       //
// Is just for the styling of the page. These are not related to the styles   //
// of the buttons. So after compiling to CSS, you may just take the part      //
// on or below the said block of comments.                                    //
// You may also customize the colors of the buttons, or actually add a new    //
// class for the buttons, by editing the $btn-type map before compiling the   //
// source code into CSS.                                                      //
////////////////////////////////////////////////////////////////////////////////


.btn {

}


// The Map for the CSS class of the buttons
$btn-type: (
        "info": (
                "color": #ffffff,
                "background-color": #0091ea,
        ),
        "success": (
                "color": #ffffff,
                "background-color": #00c853,
        ),
        "warning": (
                "color": #3e2723,
                "background-color": #ffc400,
        ),
        "error": (
                "color": #ffffff,
                "background-color": #d50000,
        ),
        "dark": (
                "color": #ffffff,
                "background-color": #303030,
        ),
);

.btn {
  aspect-ratio: 49 / 89;
  background-color: transparent;
  width: 94px;
  height: 89px;
  position: relative;
  border: none;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.54));

  &:active:after {
    background-position: -109px -5px;
  }

  &:before, &:after {
    position: absolute;
    content: '';
  }

  &:before {
    top: 3px;
    left: 3px;
    width: 86px;
    height: 84px;
    z-index: 0;
    background-color: var(--background-color);
    border-radius: 50%;
  }

  &:after {
    inset: 0;
    z-index: 1;
    background-image: url("../../transparent_button_small_sprite.png");
    background-position: -5px -5px;
  }
}

@each $type, $styles in $btn-type {
  .btn-#{$type} {
    @each $style, $value in $styles {
      --#{$style}: #{$value};
      @if ($style == "background-color") {
        --border-color: #{darken($value, 7.5%)};
      }
      &:active {
        @if ($style == "color") {
          --#{$style}: #{darken($value, 7.5%)};
        }
      }
    }
  }
}
