.table {
  user-select: none;

  &Shadow {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
  tr {
    border-bottom: 1px solid var(--greyscale-grey-400, #CED1D6);
    padding: 13px 0px 14px 0px;

    td, th {
      //padding: 13px 4px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    transform: translateY(calc(var(--delta-y) * 1px));
    transition: all .3s;

    &:not(.grabbed):hover {
      .grabIcon {
        opacity: .5;
      }
    }

    &.grabbed {
      transition: none;
      background: white;
      z-index: 1;
      position: relative;
      pointer-events: none;
    }
  }
}

.grabIcon {
  pointer-events: none;
  width: 15px;
  aspect-ratio: 1.61;
  opacity: 0;
  transition: all .3s;

  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(255,255,255,1) 21%, rgba(255,255,255,1) 40%, rgba(0,0,0,1) 41%, rgba(0,0,0,1) 60%, rgba(255,255,255,1) 61%, rgba(255,255,255,1) 80%, rgba(0,0,0,1) 81%);
}
