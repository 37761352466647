.combName {
  margin: 0;
  font-size: 20px;
  white-space: nowrap;
}
.combDescription {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}