@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

$basicBlack: #101114;
$basicWhite: #FFF;
$greyscaleGrey200: #6C717A;
$greyscaleGrey300: #969AA3;
$greyscaleGrey400: #CED1D6;
$greyscaleGrey500: #F0F2F5;
$accentPrimary: #3243E0;
$accentPrimaryDark1: #2131CC;
$accentPrimaryDark2: #1223B8;
$accentSecondary: #00AD45;
$accentTertiary: #FF4133;
$accentAdditional100: #802FEB;
$accentAdditional150: #F3EBFF;
$accentAdditional200: #FF871F;
$accentAdditional250: #FFF1E5;

@function hexToRGBString($hexColor) {
    @return "#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)}";
}

:root {
    --basic-black: #{$basicBlack};
    --basic-black-channels: #{hexToRGBString($basicBlack)};
    --basic-white: #{$basicWhite};
    --basic-white-channels: #{hexToRGBString($basicWhite)};
    --greyscale-grey-200: #{$greyscaleGrey200};
    --greyscale-grey-200-channels: #{hexToRGBString($greyscaleGrey200)};
    --greyscale-grey-300: #{$greyscaleGrey300};
    --greyscale-grey-300-channels: #{hexToRGBString($greyscaleGrey300)};
    --greyscale-grey-400: #{$greyscaleGrey400};
    --greyscale-grey-400-channels: #{hexToRGBString($greyscaleGrey400)};
    --greyscale-grey-500: #{$greyscaleGrey500};
    --greyscale-grey-500-channels: #{hexToRGBString($greyscaleGrey500)};
    --accent-primary: #{$accentPrimary};
    --accent-primary-channels: #{hexToRGBString($accentPrimary)};
    --accent-primary-dark-1: #{$accentPrimaryDark1};
    --accent-primary-dark-1-channels: #{hexToRGBString($accentPrimaryDark1)};
    --accent-primary-dark-2: #{$accentPrimaryDark2};
    --accent-primary-dark-2-channels: #{hexToRGBString($accentPrimaryDark2)};
    --accent-secondary: #{$accentSecondary};
    --accent-secondary-channels: #{hexToRGBString($accentSecondary)};
    --accent-tertiary: #{$accentTertiary};
    --accent-tertiary-channels: #{hexToRGBString($accentTertiary)};
    --accent-additional-100: #{$accentAdditional100};
    --accent-additional-100-channels: #{hexToRGBString($accentAdditional100)};
    --accent-additional-150: #{$accentAdditional150};
    --accent-additional-150-channels: #{hexToRGBString($accentAdditional150)};
    --accent-additional-200: #{$accentAdditional200};
    --accent-additional-200-channels: #{hexToRGBString($accentAdditional200)};
    --accent-additional-250: #{$accentAdditional250};
    --accent-additional-250-channels: #{hexToRGBString($accentAdditional250)};
}

.text {
    @each $num in (200, 300, 400, 500) {
        &-gray-#{$num} {
            color: var(--greyscale-grey-#{$num});
        }
    }
}

h1, .h1 {
    /* H1 — 40 SemiBold */
    font-size: 40px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 140%; /* 56px */
    letter-spacing: -0.8px;
}

h2, .h2 {
    /* H2 — 24 SemiBold */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
}

.t1 {
    /* T1 — 16 SemiBold */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}

.t2 {
    /* T2 — 16 Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.t3 {
    /* T3 — 14 SemiBold */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
}

.t4 {
    /* T4 — 14 Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
}

.t5 {
    /* T5 — 12 SemiBold */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
}

.t6 {
    /* T6 — 12 Regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.button {
    display: flex;
    padding: 13px 24px 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: 8px !important;
    border: none;

    color: var(--basic-white, #FFF);
    text-align: center;
    /* T1 — 16 SemiBold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */

    &-green {
        background: var(--accent-secondary);
        color: white;
    }
    &-red {
        background: var(--accent-tertiary);
        color: white;
    }
}

.cursor-pointer {
  cursor: pointer;
}

hr {
  width: 100%;
  border-top: 1px solid var(--greyscale-grey-200) !important;
}
