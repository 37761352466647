@use 'sass:math';

$ratio: 361 / 541;
$width: 60px;

.Card {
  width: #{$width};
  position: relative;
  transform: rotateY(0);
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 1s;
  transition-timing-function: ease-in;

  &.show {
    transform: rotateY(-180deg);
    transition-timing-function: ease-out;
    transform-origin: center;
  }

  &Face, &BackFace {
    border-radius: 4px;
    backface-visibility: hidden;
  }

  &Face {
    display: grid;
    background: white;
    color: var(--color);
    padding: 0 3px;
    line-height: 1em;

    aspect-ratio: $ratio;
    text-align: center;

    overflow: hidden;

    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.3), 0 0 5px black;

    position: absolute;
    inset: 0;

    transform: rotateY(-180deg);


    &.modern {
      padding: 5px;
      padding-top: 10px;
      padding-bottom: 0;
      grid-template:
            "a1  ."
            "a2 a2";
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;


      .a1 {
        grid-area: a1;
        font-size: 2em;
      }

      .a2 {
        grid-area: a2;
        font-size: 4em;
      }
    }
  }

  &BackFace {
    width: 100%;
    background: red;

    aspect-ratio: $ratio;

    --s: #{$width}; /* control the size */
    --c1: #AB3E5B;
    --c2: #FFBE40;

    --_g:
            #0000 25%,#0008 47%,var(--c1)  53% 147%,var(--c2) 153% 247%,
            var(--c1) 253% 347%,var(--c2) 353% 447%,var(--c1) 453% 547%,#0008 553%,#0000 575%;
    --_s: calc(25%/3) calc(25%/4) at 50%;
    background:
            radial-gradient(var(--_s) 100%,var(--_g)),
            radial-gradient(var(--_s) 100%,var(--_g)) calc(var(--s)/2) calc(3*var(--s)/4),
            radial-gradient(var(--_s) 0   ,var(--_g)) calc(var(--s)/2) 0,
            radial-gradient(var(--_s) 0   ,var(--_g)) 0                calc(3*var(--s)/4),
            repeating-linear-gradient(90deg,#ACCEC0 calc(25%/-6) calc(25%/6),#61A6AB 0 calc(25%/2));
    background-size: var(--s) calc(3*var(--s)/2)
  }
}

.bevel {
  -webkit-text-stroke: 2px currentColor;
  -webkit-text-fill-color: transparent;
  color: currentColor;

  &:before {
    content: attr(title);
    -webkit-text-fill-color: currentColor;
    position: absolute;
    transform: translate(30px, -45px) scale(2);
    opacity: .05;
    -webkit-text-stroke: initial;
  }
}

