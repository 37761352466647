.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;

    border: 2px solid;
    background-color: var(--backgroud);
    border-color: var(--backgroud);
    color: var(--basic-white);

    transition: {
        property: color, border-color, background-color;
        duration: .1s;
    };


    &.secondary {
        padding: 13px 24px 15px 24px;
        gap: 8px;

        &:not(:hover):not(:focus) {
            color: var(--backgroud);
        }

        background-color: transparent;
    }

    &.primary {
        padding: 13px 24px 15px 24px;
        gap: 8px;
    }

    &.icon {
        padding: 14px;
        gap: 10px;
    }

    &.text {
        padding: 8px 12px;
        gap: 4px;
        color: var(--greyscale-grey-300, #969AA3);
    }

    &:hover {
        background-color: var(--backgroud-hover);
        border-color: var(--backgroud-hover);
    }

    &:focus {
        background-color: var(--backgroud-focus);
        border-color: var(--backgroud-focus);
    }
}
.gray {
    --backgroud: transparent;
    --backgroud-hover: var(--greyscale-grey-500);
    --backgroud-focus: transparent;
}

.red {
    --backgroud: var(--accent-tertiary);
    --backgroud-hover: var(--accent-tertiary);
    --backgroud-focus: var(--accent-tertiary);
}

.yellow {
    --backgroud: var(--accent-additional-200);
    --backgroud-hover: var(--accent-additional-200);
    --backgroud-focus: var(--accent-additional-200);
}

.green {
    --backgroud: var(--accent-secondary);
    --backgroud-hover: var(--accent-secondary);
    --backgroud-focus: var(--accent-secondary);
}

.blue {
    --backgroud: var(--accent-primary);
    --backgroud-hover: var(--accent-primary-dark-1);
    --backgroud-focus: var(--accent-primary-dark-2);
}
