.bump {
  position: relative;
  text-shadow: -0.01em 0.02em 0.03em black;
}

.buttonContainer {
  background: rgba(0,0,0, 0.3);
  padding: 20px;
  border-radius: 20px;

  row-gap: 15px;

  .text {

  }
}